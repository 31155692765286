import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'videourl'
})
export class VideourlPipe implements PipeTransform {

  public transform(value): any {
    let videoId: string = value.substring(value.indexOf('=') + 1);
    return "https://www.youtube.com/embed/" + videoId + "?rel=0&enablejsapi=1&autoplay=1&controls=0&showinfo=0&modestbranding=0&fs=0&playsinline=0";
  }

}
