import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { ToasterService } from '../toaster/toaster.service';
import { AuthenticationService } from './authentication.service';

@Injectable({
  providedIn: 'root'
})
export class AuthguardService implements CanActivate {

  constructor(private router: Router,private toaster:ToasterService, public auth: AuthenticationService) { 
    // localStorage.setItem('auth-token', `Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpYXQiOjE2NjIzNzM1NTIsImlzcyI6Imh0dHBzOlwvXC9hcHBhcGkuYnVkc3BhY2V2NC5iaWdjaXR5dm91Y2hlci5jby5pbiIsImF1ZCI6Imh0dHBzOlwvXC9hcHBhcGkuYnVkc3BhY2V2NC5iaWdjaXR5dm91Y2hlci5jby5pbiIsIm5iZiI6MTY2MjM3MzU1MiwiZXhwIjoxNjYyNDU5OTUyLCJkYXRhIjp7InVzZXJuYW1lIjpudWxsLCJyb2xlTGFiZWwiOiIiLCJsYXN0TG9naW5BdCI6bnVsbH0sImp0aSI6bnVsbH0.vUMu-fc6IlDK92FYfKsiYN87Oq2Z5Is6KTnpoK6U4JE`)
  }
  
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    let authToken = this.auth.isAuthenticated();
    let pagePath = state.url;

    let checkLanding =  pagePath.search("landing");
    let checkLogin =  pagePath.search("login");
    let checkPassword =  pagePath.search("password");
    let checkEnrollUser =  pagePath.search("enroll-user");
    let checkAbouApp =  pagePath.search("about-app");


    // console.log([pagePath, 'checkOtp:'+checkOtp,"login:"+checkLogin]);
    window.localStorage.setItem('pageUrl',state.url);
    // debugger
      if (authToken) { 
          // logged in so return true

          if(checkLanding !== -1) return false
          if(checkLogin !== -1) return false
          if(checkPassword !== -1) return false
          if(checkEnrollUser !== -1) return false
          if(checkAbouApp !== -1) return false
          // if(checkTabs !== -1) return false
          
          return true;
      }
      
      if(checkLanding !== -1) return true
      if(checkLogin !== -1) return true
      if(checkPassword !== -1) return true
      if(checkEnrollUser !== -1) return true
      if(checkAbouApp !== -1) return true
      // if(checkTabs !== -1) return true

          

        this.toaster.presentToast("Please login!");

      // not logged in so redirect to login page with the return url and return false
      // this.router.navigate(['login'], { queryParams: { returnUrl: state.url }});
      return false;
  }
}
