import { NgModule } from '@angular/core';
import { SafePipe } from './safe.pipe';
import { VideourlPipe } from './videourl.pipe';

@NgModule({
  declarations: [
    SafePipe,
    VideourlPipe
  ],
  imports: [],
  exports: [
    SafePipe,
    VideourlPipe
  ],
})
export class PipesModule {}
