import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {IonicModule} from '@ionic/angular';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { PyErrorsComponent } from './py-errors/py-errors.component';
import { VideoPlayerComponent } from './video-player/video-player.component';
import {PipesModule} from '../pipes/pipes.module';

@NgModule({
  imports: [
    CommonModule,
    IonicModule.forRoot(),
    ReactiveFormsModule, FormsModule,
    PipesModule
  ],
  declarations: [
    PyErrorsComponent,
    VideoPlayerComponent,
  ],
  exports: [
    PyErrorsComponent,
    VideoPlayerComponent,
  ]
})
export class ComponentsModule {
}
