import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthguardService } from './services/authguard/authguard.service';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    // redirectTo: 'user-profile/feedback',
    pathMatch: 'full'
  },
  {
    path: 'login',
    canActivate:[AuthguardService],
    loadChildren: () => import('./pages/onbording/login/login.module').then( m => m.LoginPageModule)
  },
  
  {
    path: 'password',
    canActivate:[AuthguardService],
    loadChildren: () => import('./pages/onbording/password/password.module').then( m => m.PasswordPageModule)
  },
  {
    path: 'tearms-and-condition',
    // canActivate:[AuthguardService],
    loadChildren: () => import('./pages/onbording/tearms-and-condition/tearms-and-condition.module').then( m => m.TearmsAndConditionPageModule)
  },
  {
    path: 'dashboard',
    // canActivate:[AuthguardService],
    loadChildren: () => import('./pages/dashboard/dashboard.module').then( m => m.DashboardPageModule)
  },
  {
    path: 'landing',
    loadChildren: () => import('./pages/onbording/landing/landing.module').then( m => m.LandingPageModule)
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
