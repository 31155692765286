import { NavController, Platform } from '@ionic/angular';
import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';
import { BehaviorSubject, EMPTY } from 'rxjs';
import { Observable as __Observable } from 'rxjs';
import { Router } from '@angular/router';
 
const TOKEN_KEY = 'auth-token';
 
@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
 
  authenticationState = new BehaviorSubject(false);
 
  constructor(private storage: Storage, private plt: Platform, private nav: NavController,private router : Router) { 
    // localStorage.setItem('auth-token', `Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpYXQiOjE2NjI1MzEwNTMsImlzcyI6Imh0dHBzOlwvXC9hcHBhcGkuYnVkc3BhY2V2NC5iaWdjaXR5dm91Y2hlci5jby5pbiIsImF1ZCI6Imh0dHBzOlwvXC9hcHBhcGkuYnVkc3BhY2V2NC5iaWdjaXR5dm91Y2hlci5jby5pbiIsIm5iZiI6MTY2MjUzMTA1MywiZXhwIjoxNjYyNjE3NDUzLCJkYXRhIjp7InVzZXJuYW1lIjpudWxsLCJyb2xlTGFiZWwiOiIiLCJsYXN0TG9naW5BdCI6bnVsbH0sImp0aSI6bnVsbH0.lX7Mao_diu5LkYhDypMIKfesUb9dDB3VVn4Ph4uZFvE`)
    this.plt.ready().then(() => {
      this.checkToken();
    });
  }
 
  checkToken() {
    console.log("url",this.router.url)
    this.storage.get(TOKEN_KEY).then(res => {
      if (res) {
        this.authenticationState.next(true);
      }
    })
  }
  getAuthToken(){
    return window.localStorage.getItem(TOKEN_KEY);
  }
 
  login(token) {
    return this.storage.set(TOKEN_KEY, 'Bearer '+token).then(() => {
      window.localStorage.setItem(TOKEN_KEY,'Bearer '+token);
      this.authenticationState.next(true);
      
    });
  }
 
  logout() {
    return this.storage.remove(TOKEN_KEY).then(() => {
      window.localStorage.removeItem(TOKEN_KEY);
      localStorage.clear();
      this.authenticationState.next(false);
    });
  }
 
  isAuthenticated() {
    return this.authenticationState.value;
  }
 
}